@media screen and (max-width: 480px) {
  body {
    min-height: 10em;
  }
}
/*
mobile dashboard
 */
$mobile-app-dashboard-height: 60vh;
$mobile-app-dashboard-item-color: $dark-gray;
$mobile-app-dashboard-img-size: rem-calc(50);
$mobile-app-dashboard-bg: transparent;

.mobile-app-dashboard-header {
  display: flex;
  height: 100vh - $mobile-app-dashboard-height;
  align-items: center;
  justify-content: center;
  background: url("../img/homeback_white.jpg")center center;
  background-repeat: no-repeat;
  background-size: cover;
  h1 {
    color: $white;
    text-align: center;
    font-size: 22px;
    background: rgba($white, 0.2);
  }
}

.mobile-app-dashboard {
  background-color: $mobile-app-dashboard-bg;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  height: $mobile-app-dashboard-height;

  a {
    flex-basis: 50%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    color: $mobile-app-dashboard-item-color;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $mobile-app-dashboard-item-color;

    &:nth-child(2n-1){
      border-right: 1px solid $mobile-app-dashboard-item-color;
    }
  }

  .mobile-app-dashboard-inner {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    img,
    svg,
    i {
      max-width: $mobile-app-dashboard-img-size;
      max-height: $mobile-app-dashboard-img-size;
      font-size: $mobile-app-dashboard-img-size;
    }
  }
}


.parallax-background {
  background-color: #0078A0;
  height: 620px;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  width: 100%;
}

.parallax-content {
  max-width: 100%;
  position: relative;
  top: 500px;
  padding: 50px;
  font-size: 20px;
  background-color: #fff;
}

.parallax-footer{
  max-width: 100%;
  position: relative;
  top: 500px;
  padding: 10px;
  font-size: 20px;
  background-color: #fff;
}
@media print, screen and (min-width: 480px) {
  .parallax-background {
    height: 12em;
  }
  .parallax-content{
    top:12em;
  }

}

@media print, screen and (min-width: 900px) {
  .parallax-background {
    height: 25em;
  }
  .parallax-content{
    top:20em;
  }

}

@media screen and (min-width: 75em) {
  .parallax-background {
    height: 30em;
  }
  .parallax-content{
    top:25em;
  }

}
.simple-subscription-form {
  background: #0c3e5f;
  color: #fefefe;
  padding: 2rem;
  border-radius: 0;
}

.simple-subscription-form .button {
  margin-bottom: 0;
  border-radius: 0 0 0 0;
}
.full-width-testimonial {
  background-color: #0c3e5f;
  background-position: center center;
}
.button{
  background-color: #968E7C !important;
}
.button:hover{
  background-color: #9F9889 !important;
  cursor: pointer;
}
.full-width-testimonial-icon .quote-path {
  fill: none;
  stroke: #ffae00;
  stroke-width: 2.5192;
  stroke-miterlimit: 10;
}

.full-width-testimonial-content,
.full-width-testimonial-icon {
  text-align: center;
}

.full-width-testimonial-section {
  padding-top: 2rem;
}

.full-width-testimonial-section .full-width-testimonial-text {
  font-weight: 300;
  color: #ffffff;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: -5px;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 1.5em;
}

@media print, screen and (min-width: 40em) {
  .full-width-testimonial-section .full-width-testimonial-text {
    padding-left: 100px;
    padding-right: 100px;
    font-size: 1.2em;
  }
}

@media print, screen and (min-width: 64em) {
  .full-width-testimonial-section .full-width-testimonial-text {
    padding-left: 180px;
    padding-right: 180px;
    font-size: 1.4em;
  }
}

@media screen and (min-width: 75em) {
  .full-width-testimonial-section .full-width-testimonial-text {
    padding-left: 300px;
    padding-right: 300px;
  }
}

.full-width-testimonial-section .full-width-testimonial-source {
  font-size: 1.2em;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding-bottom: 0;
  margin-bottom: 0.2em;
  font-size: 1.0em;
}

.full-width-testimonial-section .full-width-testimonial-source-context {
  display: block;
  font-size: 0.9em;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 0.8em;
}

.full-width-testimonial-section .slick-slider {
  margin-bottom: 0px;
}

.full-width-testimonial-text,
.full-width-testimonial-content,
.full-width-testimonial-source,
.full-width-testimonial-icon {
  padding-bottom: 2rem;
}

.full-width-testimonial-content {
  width: 100%;
}

.gmaps-container,.aboutus-container,.reservations,.contact-container{
  padding: 0px !important;
}
.reservations{
  min-height: 9em;

}
.desk-icon{
  font-size: 6em !important;
}

.reservations-button {
  a{
    font-size: 1.3em !important;
    color: white;
  }
  svg.svg-inline--fa.fa-phone {
  font-size: 1em !important;
  color: white;
}
}

.reservations-button{
  line-height: 2!important;
  padding-top:3em ;
  padding-bottom:3em ;
  height: 100%;
  align-content:  center;
}
button.small-4.medium-6.columns.text-center.button.reservations-button span{
  font-size: 1.5em !important;
  margin: 1em;
}

.tag-cloud {
  margin: 1rem;
  text-align: center;
  list-style: none;
}

.three-column-footer-contact-form-container {
  background-color: #8a8a8a;
}

.three-column-footer-contact-form-container .three-column-footer-contact-form {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  color: #e6e6e6;
  padding: 3.75rem 0;
}

.three-column-footer-contact-form-container .three-column-footer-contact-form::before, .three-column-footer-contact-form-container .three-column-footer-contact-form::after {
  display: table;
  content: ' ';
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.three-column-footer-contact-form-container .three-column-footer-contact-form::after {
  clear: both;
}

.three-column-footer-contact-form-container .three-column-footer-contact-form a {
  color: #e6e6e6;
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-left {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  display: table;
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-left .baseline {
  display: table-cell;
  vertical-align: bottom;
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-left .contact-details p {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-left .input-group {
  padding: 2rem 0 0 0;
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-left .input-group input {
  border-bottom: 2px solid #cacaca;
}

@media print, screen and (min-width: 40em) {
  .three-column-footer-contact-form-container .three-column-footer-contact-form .footer-left {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-left:last-child:not(:first-child) {
  float: right;
}

@media print, screen and (min-width: 64em) {
  .three-column-footer-contact-form-container .three-column-footer-contact-form .footer-left {
    width: 25%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .three-column-footer-contact-form-container .three-column-footer-contact-form .footer-left {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .three-column-footer-contact-form-container .three-column-footer-contact-form .footer-left:last-child:not(:first-child) {
    float: right;
  }
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-center {
  text-align: left;
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  display: table;
}

@media print, screen and (min-width: 40em) {
  .three-column-footer-contact-form-container .three-column-footer-contact-form .footer-center {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-center:last-child:not(:first-child) {
  float: right;
}

@media print, screen and (min-width: 64em) {
  .three-column-footer-contact-form-container .three-column-footer-contact-form .footer-center {
    width: 33.33333%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    position: relative;
    left: 8.33333%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .three-column-footer-contact-form-container .three-column-footer-contact-form .footer-center {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .three-column-footer-contact-form-container .three-column-footer-contact-form .footer-center:last-child:not(:first-child) {
    float: right;
  }
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-center .baseline {
  display: table-cell;
  vertical-align: bottom;
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-center .input-group {
  padding: 0.5rem 0 0 0;
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-center .input-group input {
  border-bottom: 2px solid #cacaca;
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-center .input-group textarea {
  border-bottom: 2px solid #cacaca;
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-right {
  text-align: right;
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  display: table;
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-right .baseline {
  display: table-cell;
  vertical-align: bottom;
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-right h2 {
  color: #fefefe;
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-right p {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-right .fa {
  padding: 1rem 0 1rem 0.2rem;
}

@media print, screen and (min-width: 40em) {
  .three-column-footer-contact-form-container .three-column-footer-contact-form .footer-right {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.three-column-footer-contact-form-container .three-column-footer-contact-form .footer-right:last-child:not(:first-child) {
  float: right;
}

@media print, screen and (min-width: 64em) {
  .three-column-footer-contact-form-container .three-column-footer-contact-form .footer-right {
    width: 25%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .three-column-footer-contact-form-container .three-column-footer-contact-form .footer-right {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .three-column-footer-contact-form-container .three-column-footer-contact-form .footer-right:last-child:not(:first-child) {
    float: right;
  }
}


.bl {
  padding: 0;
  margin: 0;
  bottom: 20px;
  z-index: -1;
  background-color: #333333;
  position: fixed;
  width: 100%;
}

.featured-testimonials-section {
  background: #e6e6e6;
  padding: 2rem 1rem;
}

.featured-testimonials-section .featured-testimonials-container .orbit-previous {
  color: #0a0a0a;
  left: -1rem;
}

.featured-testimonials-section .featured-testimonials-container .orbit-next {
  color: #0a0a0a;
  right: -1rem;
}

.featured-testimonials-section .testimonial {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 2rem 1rem;
}

.featured-testimonials-section .profile-pic {
  max-width: 100px;
  border-radius: 50%;
}

.featured-testimonials-section .featured-testimonials-quotation {
  margin-left: 3rem;
  margin-bottom: 0rem;
  padding: 1rem;
  position: relative;
}

.featured-testimonials-section .featured-testimonials-quotation::before {
  content: "“";
  font-size: 5rem;
  color: #d6d6d6;
  position: absolute;
  top: -2.5rem;
  left: -1.5rem;
}

.featured-testimonials-section .featured-testimonials-quotation::after {
  content: "”";
  font-size: 5rem;
  color: #d6d6d6;
  position: absolute;
  bottom: -4rem;
  right: -.5rem;
}

@media screen and (max-width: 39.9375em) {
  .featured-testimonials-section .featured-testimonials-quotation {
    margin-left: 0;
  }
}



.footer-copy {
  padding: 0;
  margin: 0;
  bottom: 0;
  z-index: -1;
  font-size: 0.6em;
  color: white;
  background-color: #333333;
  position: fixed;
  text-align: center;
  width: 100%;

}
.contact-container{
  background-image: url("../img/contactusback.jpg");
  padding:1em !important;
  .callout{
    background: transparent!important;
    border: none!important;
    margin: 6em 0 1rem 0;
    color: #fefefe !important;
  }
  a{
    color: #fefefe !important;
  }
  li{
    font-size: 1.5em;
  }
  .contact-us-list {
    list-style-type: none;
    margin: 0; }
  .translucent-form-overlay {
    width: 100%;
    background-color: rgba(54, 54, 54, 0.8);
    padding: 20px;
    color: #fefefe;
  }
  [type="text"], [type="password"], [type="date"], [type="datetime"], [type="datetime-local"], [type="month"], [type="week"], [type="email"], [type="number"], [type="search"], [type="tel"], [type="time"], [type="url"], [type="color"], textarea{

    background-color: rgba(255, 247, 255, 0.3);
    border: 1px solid #393639;
  }
}
.social-links{
  padding: 0 !important;
  min-height: 5em;
  .button{
    line-height: 2!important;
    height: 100%;
    align-content:  center;
  }
  a{
    font-size: 2em;
    color:white;
  }
}
.parossunbed{
  background-image: url("../img/sunbed.png");
}
.features {
padding: 0px !important;
  padding-bottom: 2em!important;
  .feature-f{
    line-height: 2;
  }
  .marketing-site-features-headline {
    text-align: center;
    color: #1779ba;
    line-height: 2.7;
  }
}


